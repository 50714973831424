<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-row class="mt-5">
          <v-col :cols="cols">
            <v-text-field
              v-model="form.type"
              label="Coupon Type"
              disabled
            ></v-text-field>
            <!--
            <v-select
              v-else
              v-model="form.type"
              label="Coupon Type"
              :items="typeOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              style="width: 200px;"
            >
            </v-select> -->

            <v-text-field
              v-model="form.code"
              label="Coupon Code"
              :rules="[required]"
              :disabled="!!resource"
              @change="handleCode"
            >
            </v-text-field>

            <translatable-input
              v-model="form.name"
              label="Display Name, e.g. 10% off Attractions & MixCare Passes"
            >
            </translatable-input>

            <translatable-editor
              v-model="form.description"
              class="mb-4"
              label="Description html, e.g. <p>Expires: 3 Jul 2024</p> <a href=''>See list</a>"
            >
            </translatable-editor>

            <translatable-input
              v-model="form.footer"
              label="Footer, e.g. Exclusively for Hong Kong & Macau.."
            >
            </translatable-input>

            <v-select
              v-model="form.channel_id"
              label="Specified Channel (empty = all)"
              :items="channelOptions"
              item-text="title"
              item-value="value"
              outlined
              clearable
            ></v-select>

            <slot name="products"></slot>

            <slot name="skus"></slot>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <div class="d-flex align-center">
              <v-text-field
                v-model="form.value"
                class="mr-2"
                label="Deduction Value"
                hint="deduct on whole checkout, or each specified item"
                :rules="[between(form.value, 0, form.type === 'percentage' ? 100 : 9999999)]"
                :disabled="fullPercentage"
              ></v-text-field>

              <!-- :suffix="form.type === 'absolute' ? 'hkd' : '%'" -->

              <v-select
                v-model="form.value_type"
                label="Deduction Type"
                :items="valueTypeOptions"
                item-text="title"
                item-value="value"
                :disabled="fullPercentage"
                outlined
                dense
                hide-details
                style="width: 100px;"
              ></v-select>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <v-text-field
              v-model="form.max_deduction"
              class="mr-5"
              label="max coupon deduction"
              :prefix="baseCurrency.long_symbol"
              hint="set 0 for unlimited deduction. This is the max value of coupon deduction for each checkout."
              :rules="[between(form.max_deduction, 0, 999999)]"
              :disabled="freeTrial"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <div class="d-flex">
              <v-text-field
                v-model="form.usage_limit"
                class="mr-5"
                label="Total Usage Limit"
                suffix="ppl"
                hint="set 0 for unlimited global usage"
                :rules="[between(form.usage_limit, 0, 999999)]"
              ></v-text-field>

              <v-text-field
                v-model="form.usage_per_user"
                label="Max Usage Per User"
                suffix="ppl"
                hint="set 0 for unlimited per user usage"
                :rules="[between(form.usage_per_user, 0, 999)]"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <div class="d-flex">
              <v-text-field
                v-model="form.min_item_total"
                class="mr-5"
                label="min items total"
                :prefix="baseCurrency.long_symbol"
                hint="set 0 = unrestricted. fail with unmet items total."
                :rules="[between(form.min_item_total, 0, 999999)]"
              ></v-text-field>

              <v-text-field
                v-model="form.max_item_total"
                class="mr-5"
                label="max items total"
                :prefix="baseCurrency.long_symbol"
                hint="set 0 = unrestricted. fail with exceeded items total."
                :rules="[between(form.max_item_total, 0, 999999)]"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <h3 class="mt-2">
              Valid From
            </h3>
            <date-time-picker v-model="form.started_at">
            </date-time-picker>
          </v-col>

          <v-col :cols="cols">
            <h3 class="mt-2">
              Valid Until
            </h3>
            <date-time-picker v-model="form.expires_at">
            </date-time-picker>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :disabled="disabledFields.indexOf('active') >= 0"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import {
DateTimePicker, TranslatableEditor, TranslatableInput, TranslationLocales,
} from '@/components'
import { useCurrency, useTranslatable } from '@/composables'
import channelStore from '@/modules/channel/store'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    DateTimePicker, TranslationLocales, TranslatableInput, TranslatableEditor,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    type: {
      type: String,
      default: 'standard',
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const type = props.resource ? props.resource.type : props.type

    const fullPercentage = computed(() => ['free_trial', 'free_shipping'].includes(type))
    const freeTrial = computed(() => ['free_trial'].includes(type))

    const getValueType = () => {
      if (fullPercentage.value) return 'percentage'

      return 'absolute'
    }

    const initialForm = {
      type,
      code: props.resource ? props.resource.code : '',
      name: props.resource ? props.resource.name : useTranslatable(),
      description: props.resource ? (props.resource.description || useTranslatable()) : useTranslatable(),
      footer: props.resource ? (props.resource.footer || useTranslatable()) : useTranslatable(),
      channel_id: props.resource ? props.resource.channel_id : '',
      usage_limit: props.resource ? props.resource.usage_limit : 0,
      usage_per_user: props.resource ? props.resource.usage_per_user : 0,
      min_item_total: props.resource ? props.resource.min_item_total : 0,
      max_item_total: props.resource ? props.resource.max_item_total : 0,
      max_deduction: props.resource ? props.resource.max_deduction : 0,
      value: props.resource ? props.resource.value : 100,
      value_type: props.resource ? props.resource.value_type : getValueType(),
      active: props.resource ? props.resource.active : true,
      started_at: props.resource ? props.resource.started_at : now(),
      expires_at: props.resource ? props.resource.expires_at : now(),
    }

    initialForm.started_at = now(initialForm.started_at).toString()
    initialForm.expires_at = now(initialForm.expires_at).toString()

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const channelOptions = computed(() => channelStore.state.channels.map(c => ({ value: c.id, title: c.code })))

    const { baseCurrency } = useCurrency()

    const valueTypeOptions = [
      { title: baseCurrency.value.long_symbol, value: 'absolute' },
      { title: '%', value: 'percentage' },
    ]

    // const typeOptions = [
    //   { title: 'Standard', value: 'standard' },
    //   { title: 'Free Trial (plan)', value: 'free_trial' },
    //   { title: 'Free Shipping', value: 'free_shipping' },
    // ]

    const validate = () => {
      const data = { ...form.value }
      data.started_at = now(data.started_at).toString()
      data.expired_at = now(data.expired_at).toString()

      if (formElem.value.validate()) emit('submit', data)
    }

    const handleCode = text => {
      form.value.code = (text || '').toUpperCase()
    }

    return {
      form,
      formElem,
      validate,
      between,
      required,

      fullPercentage,
      freeTrial,

      channelOptions,
      valueTypeOptions,
      baseCurrency,

      // typeOptions,

      handleCode,
      uploadUrl: 'product',
    }
  },
}
</script>

<style lang="scss">
.content-editors {
  .ck-content {
    height: 500px; // so that it does not overflow the stepper
  }
}
</style>
